<template>
    <div>

    </div>
</template>

<script>

export default {
  created() {
       document.title ='404';
    this.$router.push('/');
   }
}
</script>

